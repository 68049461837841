<script setup lang="ts">
import { ExclamationTriangleIcon } from '@heroicons/vue/24/solid';
import { usePageContext } from 'vike-vue/usePageContext';

    // Error Parsing
    const { urlParsed } = usePageContext();
    const errorMessage = urlParsed.search.error_description
        ?? urlParsed.search.error
        ?? 'An unknown error occurred';

</script>


<template>
    <div class="flex flex-col items-center">

        <section class="m-6 p-4 bg-red-700 text-white rounded-lg border border-red-800 max-w-[600px] grow w-full">
            
            <h1 class="text-3xl font-bold flex align-middle">
                <ExclamationTriangleIcon class="size-9 me-4" />
                <span>An Error Occurred</span>
            </h1>

            <div class="mt-4">
                {{ errorMessage }}
            </div>

        </section>

    </div>
</template>
